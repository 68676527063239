@import 'variables.scss';
@import 'layout.scss';

.base {
  color: #000;
  font-family: serif;
  font-size: 18px;
}

.gram {
  color: #000;
  font-family: serif;
  font-size: 128px;
}

.animated_image {
  position: relative;
  cursor: pointer;
  text-align: left;

  &.border-main {
    border: 2px solid $color-main;
  }

  &__first {
    transition: all 0.3s ease;
  }

  &__second {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    transform: scale(0.95);
  }

  &:hover {
    .animated_image__second {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }

    .animated_image__first {
      transform: scale(0.95);
    }
  }
}

.account_box {
  position: relative;

  .btn {
    overflow: hidden;
  }
}

.header_balance {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  font-size: 14px;
  padding-right: 16px;
  color: rgb(104, 104, 104);
  white-space: nowrap;

  br {
    display: none;
  }
}

button[disabled] {
  cursor: not-allowed;
}

.status {
  z-index: 1001;
  position: fixed;
  background-color: #121212;
  color: #fff;
  display: block;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0;
  margin: 0.4rem auto;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  width: calc(100vw - 2rem);
  word-break: break-word;

  a {
    color: #f2bb1e;
  }

  img {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: #1d1919de;

  color: #030f30;

  .close {
    color: #000;
    float: right;
    font-size: 40px;
    line-height: 1;
    right: 30px;
    top: 10px;
    position: absolute;
    opacity: 1;
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media all and (max-width: 991px) {
    .close {
      top: 10px;
      right: 30px;
      font-size: 60px;
    }
  }

  @media all and (max-width: 767px) {
    .close {
      top: 0px;
      right: 10px;
    }
  }
}

.modal-inner {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 35px 47px;
  border: 1px solid #888;
  width: 780px;
  max-width: 100%;
  background: white;
  border-radius: 20px;
  position: relative;

  @media all and (max-width: 991px) {
    padding: 30px;
    border-radius: 10px;
  }
}

.modal-header {
  margin-bottom: 22px;
  text-align: center;
  padding: 0;
  text-align: left;
  border-bottom: 0;

  p {
    font-size: 24px;
    line-height: 1.4;
  }

  h2 {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: 14px;
  }

  @media all and (max-width: 767px) {
    padding: 0 20px;

    h2 {
      font-size: 20px;
      line-height: 1.4;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}

.modal-header {
  padding: 0 !important;
}

.modal_nft {
  &__title {
    font-weight: 900;
    font-size: 70px;
    line-height: 90px;
    margin-bottom: 40px;
  }

  &__preview {
    width: 300px;
    max-width: 100%;
  }

  &__caption {
    font-weight: 900;
    margin-top: 10px;
    color: #4a4a4a;
  }

  &__info {
    padding-left: 40px;
  }

  .btn {
    background-color: #030f30;
  }

  @media all and (max-width: 1080px) {
    &__preview {
      width: 450px;
    }
  }

  @media all and (max-width: 767px) {
    &__preview {
      width: 300px;
    }

    .modal-header {
      font-size: 20px;
    }
  }

  @media all and (max-width: 991px) {
    &__title {
      font-size: 38px;
      line-height: normal;
      margin-bottom: 20px;
    }
  }

  @media all and (max-width: 767px) {
    text-align: center;

    &__title {
      margin-top: 40px;
    }

    &__preview {
      margin-left: auto;
      margin-right: auto;
    }

    &__info {
      padding-left: 0;
    }
  }
}

iframe {
  width: 100%;
  border: 0;
  height: 100%;
  padding: 0;
}

.banner_hint {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 20px;

  a {
    color: #fff;

    &:hover {
      color: $action-3;
    }
  }
}

.toastify {
  background: #fff !important;
  border: 2px solid #000;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
  border-radius: 5px;
  color: #000000 !important;
  padding: 15px 25px 15px 45px !important;
  max-width: calc(70% - 20px) !important;
  display: flex !important;
  align-items: center;

  .toast-close {
    font-size: 0;
    position: absolute;
    left: 16px;
    top: 50%;
    width: 15px;
    height: 15px;
    transform: translate(0, -50%);
    opacity: 1 !important;
    margin-top: -2px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      border-bottom: 4px solid;
      transform-origin: center;
    }

    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }

  @media all and (max-width: 767px) {
    max-width: 80vw !important;
    padding: 10px 16px 10px 30px !important;
    font-size: 14px;
    line-height: 24px;

    &:before {
      width: 10px;
      height: 10px;
    }

    .toast-close {
      left: 6px;
      width: 18px;
      height: 18px;
    }
  }
}

.opensea_icon {
  width: 23px;
  height: 23px;
  display: block;
  // background: transparent url(../images/opensea-icon.png) no-repeat center / contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%);
  }
}

.nft_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  // @media all and (min-width: 768px) {
  //   opacity: 0;
  //   visibility: hidden;
  // }

  .link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

// @media all and (min-width: 768px) {
//   .holder:hover .nft_actions {
//     opacity: 1;
//     visibility: visible;
//   }
// }

.btn.xs {
  font-size: 14px;
  line-height: 30px;
  padding: 0 26px;
  height: 30px;
}

.position-sticky {
  top: 0;
  z-index: 100;
}

.header_menu_9 {
  overflow: hidden;
}

.call_to_action_4 {
  border-bottom: 2px solid #ccc !important;
  border-top: 2px solid #ccc !important;
  background: repeating-linear-gradient(-45deg, #00000022, #00000022 1px, #fff 1px, #fff 15px);
}

#svgTooltip {
  background-color: #222533dd;
  border: 0;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.86px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;

  &:before,
  &:after {
    display: none;
  }

  &.show {
    opacity: 1;
  }
}

.svg-group {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #000;
  cursor: pointer;
  will-change: opacity;
  position: relative;
}

.svg-container {
  padding-top: 40px;

  .svg-group {
    transition: fill 0.3s ease;
    fill: #000;
    cursor: pointer;
    will-change: opacity;
    position: relative;

    &:hover {
      fill: rgba(0, 0, 0, 0.3);

      .svg-tooltip-shadow,
      .svg-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    path {
      stroke-width: 10px;
      stroke: transparent;
    }

    polygon {
      stroke-width: 20px;
      stroke: transparent;
    }
  }

  svg {
    max-width: 100%;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: auto;
    outline: none !important;
  }

  g {
    outline: none;
  }
  @media all and (max-width: 767px) {
    user-select: none;
    position: relative;

    svg {
      width: 410px;
      margin: 0 -80px;
      max-width: none;
    }

    .svg-tooltip {
      font-size: 16px;
    }

    .svg-tooltip-shadow {
      width: 160px;
      height: 80px;
    }

    foreignObject {
      width: 160px;
      height: 80px;
      position: absolute;
    }

    .svg-group {
      &:hover {
        fill: #dd3616;
      }
    }
  }
}

.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  display: grid;
  place-items: center;
  z-index: 10000;
  transition: opacity 0.5s ease;

  &.fill-inverse {
    .trigram {
      opacity: 1;
    }
  }
}

.loader_stage {
  border-radius: 50%;
  width: 400px;
  height: 400px;
  position: absolute;
  margin: -200px 0 0 -200px;
  left: 50%;
  top: 50%;
  cursor: pointer;

  &__elem {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &--animation {
      animation: spin 10s linear infinite;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000 url(../images/taiji.svg) no-repeat center / contain;
        transition: transform 5s ease-in-out;
        will-change: transform;
        transform: scale(0.5);
        border-radius: 100%;
        animation: pulse 3s linear infinite;
      }
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: transform 5s ease-in-out;
      will-change: transform;
      transform: scale(0.8);
    }

    .trigram {
      transform: scale(1.7) translate(-15px, 10px);
      opacity: 0;
      transition: all 3s ease;

      polygon {
        fill: white;
      }
    }
  }

  &:hover {
    .loader_stage__elem:after,
    .loader_stage__elem .taiji {
      transform: scale(0.7);
    }
  }

  &__hint {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    color: #f1f1f1;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    margin-top: 40px;
    transform: translate(0, -20px);
    transition: all 1s ease 0.3s;

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }

  &--2 {
    opacity: 0;
    visibility: hidden;
  }

  @media all and (max-width: 767px) {
    transform: scale(0.7);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(131, 115, 115, 0.3);
  }
  70% {
    box-shadow: 0 0 30px 60px rgba(131, 115, 115, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(131, 115, 115, 0);
  }
}

svg {
  user-select: none;
}
